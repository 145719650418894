import defaultPosition from "@/helpers/wc/positionSettings";

export const talkSettings = {
  title: 'Talk',
  name: 'talkBase',
  styles: defaultPosition,
  files: [],

  properties: {
    instructions: 76,
    greetingMessage: 'Velkommen til kvinesdal kommune.\n\nHvilken avdeling �nsker du � snakke med?\n\nDu kan velge: \n{Departments}',
    firewellMessage: 'en ansatt er p� vei til � hjelpe deg.',
    voice: 'nova',
    speed: 0.8,
    fileWaitingSound: 'cb4927c6-ed15-4abd-9fa1-427cf5a846cd.mp3',
    waitingSoundVolume: 0.3,
    repeatWaitingSound: false,
    receptionistId: '',
    receptionistMessage: 'En synshemmet person trenger hjelp',
    sendOnSilent: 1,
    stopOnSilent: 10,
    emailEmployee: false,
    smsEmployee: false,
    employeeMessage: '',
    smsVisitor: false,
    isVisitorMessage: false,
    visitorMessage: [],
    queueLength: 2,
    queueMinutes: 1,
    excludeDepartments: [],
    css: {
      button: {
        color: 'white',
        padding: '5px',
        paddingTop: '5px',
        paddingBottom: '5px',
        backgroundColor: 'rgb(23, 23, 23)',
        borderColor: 'rgb(57, 61, 121)',
        borderWidth: '0px',
        borderRadius: '0px'
      },
      picture: {
        normal: '#00c4ff',
        prompt: '#3dcd3d',
        waiting: 'yellow',
        listen: '#ef1919',
        speak: '#3dcd3d',
        stop: 'hotpink',
        send: 'olive'
      },
      dialog: {
        maxHeight: '300px',
        fontSize: 14,
        fontFamily: "Roboto",
        textAlign: 'center',
        backgroundColor: 'white',
        borderRadius: '20px',
        borderColor: '#aeb7e2',
        borderWidth: '2px',
        padding: '10px'
      },
      text: {
        user: 'rgba(0, 0, 0, 0)',
        ai: 'rgba(0, 0, 1, 1)',
        sys: 'rgba(0, 0, 0, 1)'
      }
    }
  },
  cssLegend: {
    button: {
      name: "Layout",
      padding: {
        type: "int",
        name: "Padding left/right",
        handler: val => val + 'px'
      },
      paddingBottom: {
        type: "int",
        name: "Padding bottom",
        handler: val => val + 'px'
      },
      color: {
        type: "color",
        name: "Color"
      },
      backgroundColor: {
        type: "color",
        name: "Background"
      },
      borderColor: {
        type: "color",
        name: "Border"
      },
      borderWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      },
      borderRadius: {
        type: "int",
        name: "Border Radius",
        handler: val => val + 'px'
      }
    },
    picture: {
      name: "Icon color",
      normal: {
        type: "color",
        name: "Normal"
      },
      waiting: {
        type: "color",
        name: "Waiting"
      },
      listen: {
        type: "color",
        name: "Listen"
      },
      speak: {
        type: "color",
        name: "Speak"
      },
      stop: {
        type: "color",
        name: "Stop"
      },
      send: {
        type: "color",
        name: "Send"
      }
    },
    dialog: {
      name: "Dialog Block",
      maxHeight: {
        type: "int",
        name: "MaxHeight",
        handler: val => val + 'px'
      },
      fontSize: {
        type: "int",
        name: "Font Size",
        handler: val => val + 'px'
      },
      fontFamily: {
        type: "fontFamily",
        name: "Font Family"
      },
      textAlign: {
        type: "align",
        name: "Align"
      },
      backgroundColor: {
        type: "color",
        name: "Background Color"
      },
      borderRadius: {
        type: "int",
        name: "Border Radius",
        handler: val => val + 'px'
      },
      borderColor: {
        type: "color",
        name: "Border color"
      },
      borderWidth: {
        type: "int",
        name: "Border Width",
        handler: val => val + 'px'
      },
      padding: {
        type: "int",
        name: "Padding",
        handler: val => val + 'px'
      }
    },
    text: {
      name: "Text",
      user: {
        type: "color",
        name: "User"
      },
      ai: {
        type: "color",
        name: "AI"
      },
      sys: {
        type: "color",
        name: "System"
      }
    }
  }
};
